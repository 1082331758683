import { Model } from '@vuex-orm/core'

export default class CustomerFieldOption extends Model {
	static entity = 'customerFieldOptions'

	static fields() {
		return {
			id: this.number(0),
			key: this.string(''),
			value: this.string(null).nullable(),
			defaultOption: this.boolean(false),
			label: this.string(null).nullable(),
			customerFieldTypeId: this.number(0)
		}
	}
}
