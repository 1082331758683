import defaults from '~/store/defaults'
import brandDisplayService from 'ocr-services/brand-displays'
import { flattenObject, cleanObject } from 'lowfoot-helpers/util'
import merge from 'lodash/merge'
import isEmpty from 'lodash/isEmpty'

export const state = () => ({ ...defaults.brandDisplay })

export const getters = {
	getSectionsFromCategory: function (state) {
		return (categoryName) => {
			return state[categoryName]
		}
	},
	getFlatLocale: function (state) {
		return flattenObject(state.locale)
	}
}

export const mutations = {
	reset: function (state) {
		Object.assign(state, defaults.brandDisplay)
	},
	setBrandCode: function (state, brandCode) {
		state.brandCode = brandCode
	},
	setConfig: function (state, config) {
		state.config = config
	},
	setTheme: function (state, theme) {
		state.theme = theme
	},
	setLocale: function (state, locale) {
		state.locale = locale
	},
	setConfigProperty: function (state, { sectionName, propertyName, propertyValue }) {
		state.config[sectionName][propertyName] = propertyValue
	},
	setThemeProperty: function (state, { propertyName, propertyValue }) {
		state.theme[propertyName] = propertyValue
	},
	setLocaleProperty: function (state, { propertyName, propertyValue }) {
		const propertyNameArray = propertyName.split('.')
		let propertyObject = state.locale
		let obj
		for (const propertyName of propertyNameArray) {
			obj = propertyObject[propertyName]
			if (typeof obj === 'object') {
				propertyObject = obj
			} else {
				propertyObject[propertyName] = propertyValue
			}
		}
		state.locale = Object.assign({}, { ...state.locale })
	},
	createLocaleProperty: function (state, { propertyName, propertyValue }) {
		const propertyNameArray = propertyName.split('.')
		let propertyObject = {}
		let obj = propertyObject
		let currentPosition = 0
		for (const propertyName of propertyNameArray) {
			obj[propertyName] = {}
			if (currentPosition === propertyNameArray.length - 1) {
				obj[propertyName] = propertyValue
			} else {
				obj = obj[propertyName]
			}
			currentPosition++
		}
		const locale = merge(state.locale, propertyObject)
		state.locale = { ...state.locale, ...locale }
		state.locale = Object.assign({}, { ...state.locale, ...locale })
	},
	deleteLocaleProperty: function (state, propertyName) {
		const propertyNameArray = propertyName.split('.')
		let propertyObject = state.locale
		let obj
		for (const [index, propertyName] of propertyNameArray.entries()) {
			obj = propertyObject[propertyName]
			if (typeof obj === 'object' && !isEmpty(obj) && (index < propertyNameArray.length - 1)) {
				propertyObject = obj
			} else {
				delete propertyObject[propertyName]
			}
		}
		let filteredObject = cleanObject(state.locale)
		state.locale = Object.assign({}, { ...filteredObject })
	}
}

export const actions = {
	loadBrandDisplay: async function ({ commit, dispatch, rootGetters }) {
		const brand = rootGetters['brand/get']
		commit('setBrandCode', brand.code)
		commit('setLocale', JSON.parse(brand.locale))
		commit('setConfig', JSON.parse(brand.config))
		commit('setTheme', JSON.parse(brand.theme))
	},
	createLocaleProperty: async function ({ commit, dispatch }, payload) {
		commit('createLocaleProperty', payload)
	},
	deleteLocaleProperty: async function ({ commit, dispatch }, propertyName) {
		commit('deleteLocaleProperty', propertyName)
	},
	updateLocaleProperty: async function ({ commit, dispatch }, payload) {
		commit('setLocaleProperty', payload)
	},
	updateConfigProperty: async function ({ commit, dispatch }, payload) {
		commit('setConfigProperty', payload)
	},
	updateThemeProperty: async function ({ commit, dispatch }, payload) {
		commit('setThemeProperty', payload)
	},
	updateBrandDisplay: async function ({ state, dispatch, rootGetters }) {
		const brandCode = state.brandCode ? state.brandCode : rootGetters.brand.code
		const config = JSON.stringify(state.config)
		const theme = JSON.stringify(state.theme)
		const locale = JSON.stringify(state.locale)
		await brandDisplayService.update({ brandCode, config, theme, locale })
		return dispatch('brand/loadBrand', {}, { root: true })
	}
}
