
import { mapGetters } from 'vuex'

export default {
	computed: {
		...mapGetters({
			isAuthenticated: 'isAuthenticated',
			loggedInUser: 'loggedInUser',
			brand: 'brand/get',
			brandDisplay: 'brandDisplay/getSectionsFromCategory'
		}),
		theme: function () {
			return this.brandDisplay('theme')
		}
	}
}
