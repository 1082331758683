import VuexORM from '@vuex-orm/core'
import database from '@/database'
import VuexORMAxios from '@vuex-orm/plugin-axios'

VuexORM.use(VuexORMAxios)

export const plugins = [
  VuexORM.install(database)
]

export const getters = {
	isAuthenticated (state) {
		return state.auth.loggedIn
	},

	loggedInUser (state) {
		return state.auth.user
	}
}

export const actions = {
	reset ({commit, dispatch}) {
		commit('flow/reset', { root: true })
		commit('brand/reset', { root: true })
		commit('brandDisplay/reset', { root: true })
		return dispatch('entities/deleteAll', { root: true })
	}
}
