
import { mapGetters } from 'vuex'

export default {
	name: 'NavBrandProfile',
	computed: {
		...mapGetters({
			brand: 'brand/get',
			brandDisplay: 'brandDisplay/getSectionsFromCategory'
		}),
		name: function () {
			return this.brand && this.brand.name
		},
		primaryColour: function () {
			const theme = this.brandDisplay('theme')
			return theme['--accent-colour']
		}
	}
}
