import { Model } from '@vuex-orm/core'
import CustomerFieldCategory from '@/models/CustomerFieldCategory'
import ContentItem from '@/models/ContentItem'

export default class CustomerFieldType extends Model {
	static entity = 'customerFieldTypes'

	static fields() {
		return {
			id: this.number(0),
			code: this.string(''),
			errorMessage: this.string(null).nullable(),
			validPattern: this.string(null).nullable(),
			mask: this.string(null).nullable(),
			optional: this.boolean(false),
			enableConfirmation: this.boolean(false),
			enableDuplicate: this.boolean(false),
			customerFieldCategoryId: this.number(0),
			customerFieldCategory: this.hasOne(CustomerFieldCategory, 'id', 'customerFieldCategoryId'),
			contentItem: this.hasOne(ContentItem, 'customerFieldTypeId', 'id')
		}
	}
}
