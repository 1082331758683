import { Model } from '@vuex-orm/core'
import ContentItem from '@/models/ContentItem'
import ContentGroupItem from '@/models/ContentGroupItem'

export default class ContentGroup extends Model {
	static entity = 'contentGroups'

	static fields() {
		return {
			id: this.number(0),
			toggleContentItemId: this.number(null).nullable(),
			togglePattern: this.string(null).nullable(),
			contentGroupItems: this.hasMany(ContentGroupItem, 'contentGroupId', 'id'),
			toggleContentItem: this.hasOne(ContentItem, 'id', 'toggleContentItemId')
		}
	}
}
