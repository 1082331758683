import { render, staticRenderFns } from "./admin.vue?vue&type=template&id=11ebb697&"
import script from "./admin.vue?vue&type=script&lang=js&"
export * from "./admin.vue?vue&type=script&lang=js&"
import style0 from "./admin.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavTopBar: require('/builds/lowfoot/ocr-enrollment/frontend/ocr-cms/components/nav/NavTopBar.vue').default,NavSideBar: require('/builds/lowfoot/ocr-enrollment/frontend/ocr-cms/components/nav/NavSideBar.vue').default})
