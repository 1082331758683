
import { mapGetters } from 'vuex'
export default {
	name: 'NavSideBar',
	computed: {
		...mapGetters({
			campaignId: 'flow/getSelectedCampaignId'
		})
	},
	methods: {
		resetCampaignSelection: async function () {
			this.$store.commit('flow/reset')
			await this.$router.push({ path: '/campaigns/list-campaigns' })
		},
		resetCampaignContentSelection: function () {
			this.$store.commit('flow/setSelectedCardId', null)
		}
	}
}
