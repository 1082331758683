import brandService from 'ocr-services/brands'
import defaults from "~/store/defaults";

export const state = () => ({ ...defaults.brand })

export const getters = {
	get (state) {
		return state.apiBrand
	}
}

export const mutations = {
	reset (state) {
		Object.assign(state, defaults.brand)
	},
	setApiBrand (state, apiBrand) {
		state.apiBrand = apiBrand
	}
}

export const actions = {
	async loadBrand ({ commit, dispatch }) {
		const apiBrand = await brandService.get()
		commit('setApiBrand', apiBrand)
		return dispatch('brandDisplay/loadBrandDisplay', {}, { root: true })
	}
}
