import { Model } from '@vuex-orm/core'

export default class ServiceType extends Model {
	static entity = 'serviceTypes'

	static fields() {
		return {
			id: this.number(0),
			code: this.string('')
		}
	}
}
