
import BaseButton from 'lowfoot-components/BaseButton'

export default {
	components: { BaseButton },
	methods: {
		async logout () {
			await this.$router.push('/login/')
			await this.$store.dispatch('reset')
			await this.$auth.logout()
		}
	}
}
