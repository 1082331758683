import { Model } from '@vuex-orm/core'
import ServiceType from '@/models/ServiceType'

export default class ContentDocumentScan extends Model {
	static entity = 'contentDocumentScans'

	static fields() {
		return {
			id: this.number(0),
			serviceTypeId: this.number(0),
			scope: this.string(''),
			optional: this.boolean(false),
			serviceType: this.hasOne(ServiceType, 'id', 'serviceTypeId')
		}
	}
}
