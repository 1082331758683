import { Model } from '@vuex-orm/core'
import ContentItem from '@/models/ContentItem'

export default class CardContent extends Model {
	static entity = 'cardContents'

	static fields() {
		return {
			id: this.number(0),
			cardId: this.number(0),
			contentItemId: this.number(0),
			order: this.number(0),
			contentItem: this.hasOne(ContentItem, 'id', 'contentItemId')
		}
	}
}
