import { Model } from '@vuex-orm/core'
import CustomerFieldType from '@/models/CustomerFieldType'
import ContentDocumentScan from '@/models/ContentDocumentScan'
import ContentGroup from '@/models/ContentGroup'
import CustomContent from '@/models/CustomContent'

export default class ContentItem extends Model {
	static entity = 'contentItems'

	static fields() {
		return {
			id: this.number(0),
			contentType: this.string(''),
			customerFieldTypeId: this.number(null).nullable(),
			documentScanId: this.number(null).nullable(),
			contentGroupId: this.number(null).nullable(),
			customContentId: this.number(null).nullable(),
			label: this.string(null).nullable(),
			documentScan: this.hasOne(ContentDocumentScan, 'id', 'documentScanId'),
			contentGroup: this.hasOne(ContentGroup, 'id', 'contentGroupId'),
			customerFieldType: this.hasOne(CustomerFieldType, 'id', 'customerFieldTypeId'),
			customContent: this.hasOne(CustomContent, 'id', 'customContentId')
		}
	}

}
