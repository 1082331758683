import { Model } from '@vuex-orm/core'

export default class FacilityFieldType extends Model {
	static entity = 'facilityFieldTypes'

	static fields() {
		return {
			id: this.number(0),
			name: this.string(null).nullable(),
			dataType: this.string(null).nullable()
		}
	}
}
