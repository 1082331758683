import defaults from '~/store/defaults'

export const state = () => ({
	...defaults.flow
})

export const getters = {
	getSelectedCampaignId (state) {
		return state.selectedCampaignId
	},
	getSelectedCardId (state) {
		return state.selectedCardId
	}
}

export const mutations = {
	reset (state) {
		Object.assign(state, defaults.flow)
	},
	setSelectedCampaignId (state, campaignId) {
		state.selectedCampaignId = campaignId
	},
	setSelectedCardId (state, cardId) {
		state.selectedCardId = cardId
	}
}

