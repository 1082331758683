import { Model } from '@vuex-orm/core'

export default class CampaignCsv extends Model {
	static entity = 'campaignCsvs'

	static fields() {
		return {
			id: this.number(0),
			campaignId: this.number(0),
			exportTrigger: this.string(''),
			exportTime: this.string(''),
			encodingFormat: this.string(''),
			emptyCsvAllowed: this.boolean(false)
		}
	}
}
