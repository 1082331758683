export default async function (ctx) {
	const authDisabled = checkRouteOption(ctx.route, 'auth', false)
	if (!authDisabled) {
		const user = ctx.$auth.user
		if (!(user && user.roles && (user.roles.includes('SUPERADMIN') || user.roles.includes('CLIENT_ADMIN')))) {
			ctx.redirect('/login')
		} else if (ctx.store.getters['brand/get'] === null) {
			await ctx.store.dispatch('brand/loadBrand')
		}
	}
}

function checkRouteOption (route, key, value) {
	return route.matched.some((m) => {
		return Object.values(m.components).some(
			component => component.options && component.options[key] === value
		)
	})
}
