import { Model } from '@vuex-orm/core'

export default class CustomContent extends Model {
	static entity = 'customContents'

	static fields() {
		return {
			id: this.number(0),
			content: this.string(''),
			contentType: this.string('')
		}
	}
}
