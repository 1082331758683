export default {
	brand: {
		apiBrand: null
	},
	brandDisplay: {
		brandCode: null,
		theme: {},
		locale: {},
		config: {}
	},
	flow: {
		selectedCampaignId: null,
		selectedCardId: null
	}
}
