import { Model } from '@vuex-orm/core'
import CustomerFieldType from '@/models/CustomerFieldType'
import DocumentFieldCategory from '@/models/DocumentFieldCategory'
import FacilityFieldType from '@/models/FacilityFieldType'
import ServiceType from '@/models/ServiceType'

export default class CsvField extends Model {
	static entity = 'csvFields'

	static fields() {
		return {
			id: this.number(0),
			campaignId: this.number(0),
			customerFieldTypeId: this.number(null).nullable(),
			documentFieldCategoryId: this.number(null).nullable(),
			providerNameForServiceTypeId: this.number(null).nullable(),
			facilityFieldTypeId: this.number(null).nullable(),
			enrollmentField: this.string(null).nullable(),
			code: this.string(''),
			fixedValue: this.string(null).nullable(),
			useAltered: this.boolean(false),
			customerFieldType: this.hasOne(CustomerFieldType, 'id', 'customerFieldTypeId'),
			documentFieldCategory: this.hasOne(DocumentFieldCategory, 'id', 'documentFieldCategoryId'),
			serviceType: this.hasOne(ServiceType, 'id', 'providerNameForServiceTypeId'),
			facilityFieldType: this.hasOne(FacilityFieldType, 'id', 'facilityFieldTypeId')
		}
	}
}
