import { Model } from '@vuex-orm/core'
import Card from '@/models/Card'

export default class Campaign extends Model {
	static entity = 'campaigns'

	static fields() {
		return {
			id: this.number(0),
			name: this.string(''),
			subdomain: this.string(''),
			deleted: this.boolean(false),
			cards: this.hasMany(Card, 'campaignId', 'id')
		}
	}
}
