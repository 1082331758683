import { Model } from '@vuex-orm/core'

export default class Agreement extends Model {
	static entity = 'agreements'

	static fields() {
		return {
			id: this.number(0),
			campaignId: this.number(0),
			location: this.string(''),
			type: this.string(''),
			header: this.string(''),
			scrollToEnd: this.boolean(false),
			content: this.string(''),
			order: this.number(0)
		}
	}
}
