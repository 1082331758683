import { Database } from '@vuex-orm/core'
import Campaign from '@/models/Campaign'
import Card from '@/models/Card'
import CardContent from '@/models/CardContent'
import ContentDocumentScan from '@/models/ContentDocumentScan'
import ContentGroup from '@/models/ContentGroup'
import ContentGroupItem from '@/models/ContentGroupItem'
import ContentItem from '@/models/ContentItem'
import CustomContent from '@/models/CustomContent'
import CustomerFieldCategory from '@/models/CustomerFieldCategory'
import CustomerFieldOption from '@/models/CustomerFieldOption'
import CustomerFieldType from '@/models/CustomerFieldType'
import CampaignCsv from '@/models/CampaignCsv'
import CsvField from '@/models/CsvField'
import DocumentFieldCategory from '@/models/DocumentFieldCategory'
import ServiceType from '@/models/ServiceType'
import Agreement from '@/models/Agreement'
import FacilityFieldType from '@/models/FacilityFieldType'

const database = new Database()

database.register(Campaign)
database.register(Card)
database.register(CardContent)
database.register(ContentDocumentScan)
database.register(ContentGroup)
database.register(ContentGroupItem)
database.register(ContentItem)
database.register(CustomContent)
database.register(CustomerFieldCategory)
database.register(CustomerFieldOption)
database.register(CustomerFieldType)
database.register(CampaignCsv)
database.register(CsvField)
database.register(DocumentFieldCategory)
database.register(ServiceType)
database.register(Agreement)
database.register(FacilityFieldType)

export default database
